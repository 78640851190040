import React from "react";
import PageIllustration from '../partials/PageIllustration';


class ContactFormPage extends React.Component {
  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.state = {
      feedbackSuccess: null,
      feedbackMsg: null,
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit(event) {
    // Do not submit form via HTTP, since we're doing that via XHR request.

    function encode(data) {
      return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
    }

    event.preventDefault()
    const form = event.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
    .then(response => {
      this.setState({
        feedbackSuccess: true,
      })
      this.domRef.current.reset()
    })
    .catch(err =>
      this.setState({
        feedbackSuccess: false,
      }),
    )
  }

  render() {
    const { feedbackSuccess } = this.state;

    return (

      <div className="flex flex-col min-h-screen overflow-hidden">
      <PageIllustration />
        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 mb-4" data-sal="slide-up" data-sal-duration="800" data-sal-easing="easeIn">How can we help you?</h1>
                <p className="text-xl text-gray-400" data-sal="slide-up" data-sal-duration="800" data-sal-easing="easeIn" data-sal-delay="450">We have custom plans to power your business.<br /> Tell us your needs, and we will contact you shortly.</p>
              </div>

              {/* Contact form */}
              <form ref={this.domRef} name="Contact Form" method="POST" data-netlify="true" onSubmit={event => this.handleSubmit(event)} id="contactform" className="max-w-xl mx-auto" data-sal="fade" data-sal-duration="2000" data-sal-easing="easeIn" data-sal-delay="0" encType="multipart/form-data" target="_blank" >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="first-name">First Name <span className="text-red-600">*</span></label>
                    <input id="first-name" name="first-name" type="text" className="form-input w-full text-gray-300" placeholder="Enter your first name" required onChange={this.handleChange} />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="last-name">Last Name <span className="text-red-600">*</span></label>
                    <input id="last-name" name="last-name" type="text" className="form-input w-full text-gray-300" placeholder="Enter your last name" required onChange={this.handleChange} />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                    <input id="email" name="email" type="email" className="form-input w-full text-gray-300" placeholder="Enter your email address" required onChange={this.handleChange} />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="subject">Subject <span className="text-red-600">*</span></label>
                    <input id="subject" name="subject" type="text" className="form-input w-full text-gray-300" placeholder="How can we help you?" required onChange={this.handleChange} />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="message">Message<span className="text-red-600">*</span></label>
                    <textarea id="message" name="message" rows="7" className="form-textarea w-full text-gray-300" placeholder="Your message to us" required onChange={this.handleChange} />
                  </div>
                </div>
                <button type="submit" className="btn text-white bg-purple-600 hover:bg-purple-700 w-full">
                  Send
                </button>
                <div className="flex flex-wrap mt-6">
                  <div className={"success w-full text-center pt-3 pr-3 pb-3 pl-3 bg-green-500 text-black transition duration-500 ease-in-out" + (feedbackSuccess === true ? ' active' : '')}>Your message has been sent, thank you!</div>
                  <div className={"error w-full text-center pt-3 pr-3 pb-3 pl-3 bg-red-800 text-white transition duration-500 ease-in-out" + (feedbackSuccess === false ? ' active' : '')}>Your message could not be sent. Please try again later.</div>
                </div>
              </form>

            </div>
          </div>
        </section>

      </div>
    )
  }
}

export default ContactFormPage
